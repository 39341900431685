import React, {useEffect, useState} from "react"
import styles from "./ArenaDialog.module.css"
import {io, Socket} from 'socket.io-client'
import * as process from 'process'
import {getId, getToken} from "../../../app/store";
import {RawNFT, RawPrice} from "../../../model/RawServerData";

function SocketLog(...args: any) {
    let msg = 'WS:'

    let needDelimiter = false
    for (const arg of args) {
        if (! needDelimiter) {
            needDelimiter = true
        } else {
            msg += ' |'
        }

        msg += ' ' + arg
    }

    console.log(msg)
}

interface PlayerWallets {
    ton_address:        string
    incoming_id:        string      //  for E4 top-up
    balances:           RawPrice
    fees:               RawPrice    //  blockchain fees for withdrawal
    withdrawalLimit?:   number      //  for E4 withdraw
}

interface Player {
    // alert
    id:         number
    first_name: string
    last_name:  string
    username:   string
    wallets:    PlayerWallets
    nfts:       RawNFT[]
}

function GetUserName(user: Player) {
    const invisibleSymbol = 'ᅠ ᅠ '

    if (!user.first_name.includes(invisibleSymbol) && user.first_name.trim()) return user.first_name.trim()
    if (user.username.trim()) return `@${user.username}`

    return 'unknown'
}

export function ArenaDialog() {
    const url = process.env.REACT_APP_ARENA_SOCKET_URI!

    const [players, setPlayers] = useState<Player[]>([])
    const [dataFetched, setDataFetched] = useState<boolean>(false)

    let ws: Socket

    useEffect(() => {
        if (!ws) {
            ws = io(url)

            ws.on('connect', () => {
                SocketLog('connect')
            })

            ws.on('disconnect', () => {
                SocketLog('disconnect')
            })

            ws.on('error', () => {
                SocketLog('error')
            })

            ws.onAny((...args) => {
                if (args.length === 0) {
                    SocketLog('error', 'null data')
                    return
                }

                const event = args[0]
                if (event === 'hello') {
                    SocketLog('handshake')

                    ws.emit('start', {
                        id:     getId(),
                        status: getToken(),
                    })
                } else if (event === 'players') {
                    if (args.length !== 2) {
                        SocketLog('error', 'wrong arguments count')
                        return
                    }

                    SocketLog(`got ${args[1].length} player(s)`)
                    setPlayers(args[1])
                    setDataFetched(true)
                } else {
                    SocketLog('unknown event', event)
                }
            })

            ws.connect()
        }
    }, [])








    return (
        <div className={styles.locationDialog}>
            {
                dataFetched ? (
                    <div>
                        <h2>Игроки</h2>
                        {
                            players.map(
                                (player, i) => (
                                    <div key={player.id}>{i + 1} {GetUserName(player)}</div>
                                ))
                        }
                    </div>
                ) : (<h2>Загрузка...</h2>)
            }
            {/*<div className={styles.locationDialogRow}>*/}
            {/*    <HorizontalStatView title={"Текущая локация"} text={props.location.location}/>*/}
            {/*</div>*/}
            {/*<div className={styles.locationDialogRow}>*/}
            {/*    <HorizontalStatView title={"Доступная локация"} text={props.location.nextLocation}/>*/}
            {/*</div>*/}

            {/*<div className={styles.locationDialogRow}>*/}
            {/*    <HorizontalStatView title={"Мин. баланс после обновления, me4ton"} text={props.location.minBalanceAfterUpgrade}/>*/}
            {/*</div>*/}
            {/*<div className={classNames(styles.dialogRow, styles.center)}>*/}
            {/*    <Button text={"Переехать"} disabled={!props.location.upgradeAvailable}*/}
            {/*            onClick={() => {*/}
            {/*                    if (props.location.upgradeAvailable) props.onBuyNewLocation()*/}
            {/*                }*/}
            {/*            }/>*/}
            {/*</div>*/}
        </div>
    )
}
